import "@babel/polyfill"
//import { BootstrapVue} from 'bootstrap-vue'
import { DropdownPlugin,  TabsPlugin, CardPlugin, SpinnerPlugin, ModalPlugin, CollapsePlugin } from 'bootstrap-vue'

export default {
    install(Vue) {
      Vue.use(DropdownPlugin);
      Vue.use(TabsPlugin);
      Vue.use(SpinnerPlugin);
      Vue.use(CardPlugin);
      Vue.use(ModalPlugin);
      Vue.use(CollapsePlugin);
      //Vue.use(Dropdown);
      //Vue.component("bootstrap4", BootstrapVue);
      //Vue.component("", BootstrapVue);
    }
};